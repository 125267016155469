/* You can add global styles to this file, and also import other style files */


$color: #f88d11;
;
$colorRight: darken(#f88d11, 15%);
$colorLeft: darken(#f88d11, 5%);
$shadow: #DBE3F4;

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.btn-sm {
  font-size: 12px !important;
  border-radius: 6px !important;
}

.boxes {
  margin: auto;
  margin-top: 18% !important;
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);

  .box {
    width: var(--size);
    height: var(--size);
    top: 0;
    left: 0;
    position: absolute;
    transform-style: preserve-3d;

    &:nth-child(1) {
      transform: translate(100%, 0);
      animation: box1 var(--duration) linear infinite;
    }

    &:nth-child(2) {
      transform: translate(0, 100%);
      animation: box2 var(--duration) linear infinite;
    }

    &:nth-child(3) {
      transform: translate(100%, 100%);
      animation: box3 var(--duration) linear infinite;
    }

    &:nth-child(4) {
      transform: translate(200%, 0);
      animation: box4 var(--duration) linear infinite;
    }

    &>div {
      --background: #{$color};
      --top: auto;
      --right: auto;
      --bottom: auto;
      --left: auto;
      --translateZ: calc(var(--size) / 2);
      --rotateY: 0deg;
      --rotateX: 0deg;
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--background);
      top: var(--top);
      right: var(--right);
      bottom: var(--bottom);
      left: var(--left);
      transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));

      &:nth-child(1) {
        --top: 0;
        --left: 0;
      }

      &:nth-child(2) {
        --background: #{$colorRight};
        --right: 0;
        --rotateY: 90deg;
      }

      &:nth-child(3) {
        --background: #{$colorLeft};
        --rotateX: -90deg;
      }

      &:nth-child(4) {
        --background: #{$shadow};
        --top: 0;
        --left: 0;
        --translateZ: calc(var(--size) * 3 * -1);
      }
    }
  }
}

@keyframes box1 {

  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {

  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.274), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.text-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.247), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  text-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}


$color: #f88d11;
;
$colorRight: darken(#f88d11, 15%);
$colorLeft: darken(#f88d11, 5%);
$shadow: #DBE3F4;

.boxes {
  margin: auto;
  margin-top: 18% !important;
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);

  .box {
    width: var(--size);
    height: var(--size);
    top: 0;
    left: 0;
    position: absolute;
    transform-style: preserve-3d;

    &:nth-child(1) {
      transform: translate(100%, 0);
      animation: box1 var(--duration) linear infinite;
    }

    &:nth-child(2) {
      transform: translate(0, 100%);
      animation: box2 var(--duration) linear infinite;
    }

    &:nth-child(3) {
      transform: translate(100%, 100%);
      animation: box3 var(--duration) linear infinite;
    }

    &:nth-child(4) {
      transform: translate(200%, 0);
      animation: box4 var(--duration) linear infinite;
    }

    &>div {
      --background: #{$color};
      --top: auto;
      --right: auto;
      --bottom: auto;
      --left: auto;
      --translateZ: calc(var(--size) / 2);
      --rotateY: 0deg;
      --rotateX: 0deg;
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--background);
      top: var(--top);
      right: var(--right);
      bottom: var(--bottom);
      left: var(--left);
      transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));

      &:nth-child(1) {
        --top: 0;
        --left: 0;
      }

      &:nth-child(2) {
        --background: #{$colorRight};
        --right: 0;
        --rotateY: 90deg;
      }

      &:nth-child(3) {
        --background: #{$colorLeft};
        --rotateX: -90deg;
      }

      &:nth-child(4) {
        --background: #{$shadow};
        --top: 0;
        --left: 0;
        --translateZ: calc(var(--size) * 3 * -1);
      }
    }
  }
}

@keyframes box1 {

  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {

  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 360px;
  --bs-offcanvas-height: 52vh;
  --bs-offcanvas-padding-x: 1.25rem;
  --bs-offcanvas-padding-y: 1.25rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}


.form-search {
  position: relative;

  i {
    z-index: 999 !important;
  }
}

.input-group {
  .form-control {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.width-20 {
  width: 20% !important;
}

.width-30 {
  width: 30% !important;
}

.width-32 {
  width: 32% !important;
}

.width-36 {
  width: 36% !important;
}

.width-40 {
  width: 40% !important;
}

.width-90 {
  width: 90% !important;
}

.width-95 {
  width: 95% !important;
}

.width-100 {
  width: 100% !important;
}

.f-right {
  float: right !important;
}

.f-left {
  float: left !important;
}

.dropzone {
  border: 1px dashed grey !important;
}

.asterisk:after {
  content: " *";
  color: #d11b1b;
}

.error-msg {
  color: #d11b1b;
  font-size: 14px;
  font-weight: 400;
}

.bdrb {
  // border-bottom: 1px solid lightgray;
  // border-bottom-style: dashed;
  border-bottom: 1px solid #e39c32;
  border-bottom-style: dashed;
  padding: 1px 0px;
}

.bdr-gray {
  border-top: 1px dashed #80808052
}

button.bootbox-close-button.close {
  display: none !important;
}

.scroll-overflow {
  max-height: 300px;
  overflow-y: scroll;
}

// .text-primary {
//   color: #e39c32;
// }

.form-search.input-group {
  z-index: 0;
}

.select-wrapper {
  height: 50px;
  overflow-y: visible;
}

.select {
  width: 100%;
  min-height: 50px;
  border-radius: 25px;
  border-color: #555;
  padding: 10px;
}

.pagination-div {
  width: 100%;
  height: 60px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 10px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #42484d;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
  z-index: 2;
  color: #424b53;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #ff7200 !important;
  border-color: #ff7200 !important;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.pager li>a:hover,
.pager li>a:focus {
  text-decoration: none;
  background-color: #eee;
}

.pager .next>a,
.pager .next>span {
  float: right;
}

.pager .previous>a,
.pager .previous>span {
  float: left;
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

/*---- Time line -----*/
.timeline {
  position: relative;
  margin: 0 0 2rem;
  padding: 0;
  list-style: none;
}

.timeline:before {
  background-color: #eff2f7;
  position: absolute;
  display: block;
  content: '';
  height: 100%;
  top: 0;
  bottom: 0;
  left: 4px;
}

.timeline-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2rem;
  margin: .5rem 0;
}

.timeline-item:first-child:before,
.timeline-item:last-child:before {
  content: '';
  position: absolute;
  background: #fff;
  width: 1px;
  left: .25rem;
}

.timeline-item:first-child {
  margin-top: 0;
}

.timeline-item:first-child:before {
  top: 0;
  height: .5rem;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-item:last-child:before {
  top: .5rem;
  bottom: 0;
}

.timeline-badge {
  position: absolute;
  display: block;
  width: 0.4375rem;
  height: 0.4375rem;
  left: 1px;
  top: .5rem;
  border-radius: 100%;
  border: 1px solid #fff;
  background: #adb5bd;
}

.timeline-time {
  white-space: nowrap;
  margin-left: auto;
  color: #8f8794;
  font-size: 87.5%;
}

.timeline__item:after {
  background: #fff !important;
}

.timeline__content {
  background-color: #fff;
  border-radius: 10px;
  display: block;
  padding: 1.25rem;
  position: relative;
}

/*vertical-timline*/
.cbp_tmtimeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative
}

.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #e4e6e8;
  left: 20%;
  margin-left: -6px
}

.cbp_tmtimeline>li {
  position: relative
}

.cbp_tmtimeline>li:first-child .cbp_tmtime span.large {
  font-size: 17px !important;
  font-weight: 700
}

.cbp_tmtimeline>li:first-child .cbp_tmicon {}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmtime span:last-child {
  font-size: 13px
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel {
  background: #fff;
}

[data-pc-theme=dark] .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel
{
  background:  transparent
}
[data-pc-theme=dark] .cbp_tmtimeline>li:nth-child(even) .cbp_tmlabel
{
  background:  transparent
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #ffffff;
}

[data-pc-theme=dark] .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #19212a;
}

[data-pc-theme=dark] .cbp_tmtimeline>li:nth-child(even) .cbp_tmlabel:after {
  border-right-color: #19212a;
}

[data-pc-theme=dark] .text-muted
{
  color: #c0c2c5 !important;
}

.cbp_tmtimeline>li .empty span {
  color: rgba(0, 0, 0, 0.5);
}

.cbp_tmtimeline>li .cbp_tmtime {
  display: block;
  width: 21%;
  padding-right: 70px;
  position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
  display: block;
  text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
  font-size: 15px;
  font-weight: 700
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
  font-size: 14px;
}

.cbp_tmtimeline>li .cbp_tmlabel {
  margin: 0 0 30px 25%;
  background: #fff;
  padding: 1.2em;
  position: relative;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}

.cbp_tmtimeline>li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #ffffff;
  border-width: 10px;
  top: 10px
}

.cbp_tmtimeline>li .cbp_tmlabel blockquote {
  font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel .map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
  -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
  box-shadow: 0px 0px 0px 1px #ebebeb;
  background: #3d3780 !important
}

.cbp_tmtimeline>li .cbp_tmlabel h2 {
  margin: 0px;
  padding: 0 0 5px 0;
  line-height: 26px;
  font-size: 18px;
  font-weight: 600;
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a {
  font-size: 20px
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a:hover {
  text-decoration: none
}

.cbp_tmtimeline>li .cbp_tmlabel h2 span {
  font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
  margin-bottom: 0;
}

.cbp_tmtimeline>li .cbp_tmicon {
  width: 24px;
  height: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 15px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: #46a4da;
  border-radius: 50%;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px
}

.cbp_tmtimeline .map {
  padding-top: 0 !important;
}

/*vertical-timline*/

/*vertical-timline 02*/
/** timeline left **/
.timelineleft {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

.timelineleft:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(152, 166, 173, 0.3);
  left: 31px;
  margin: 0;
  border-radius: 2px;
}

.timelineleft>li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}

.timelinelft>li:before,
.timelineleft>li:after {
  content: " ";
  display: table;
}

.timelineleft>.timeleft-label>span {
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  background-color: #1753fc;
  color: #ffffff;
  border-radius: 0px;
}

.timelineleft>li:after {
  clear: both;
}

.timelineleft>li>.timelineleft-item {
  -webkit-box-shadow: 0 1px 1px rgba(107, 122, 144, 0.1);
  box-shadow: 0 2px 17px 2px rgb(208, 201, 243, .5);
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
  border-radius: 7px;
}

.timelineleft>li>.timelineleft-item>.time {
  color: #999;
  float: right;
  padding: 12px;
  font-size: 12px;
}

.timelineleft>li>.timelineleft-item>.timelineleft-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid rgba(107, 122, 144, 0.1);
  padding: .75rem 1.25rem;
  font-size: 20px;
  line-height: 1.1;
}

.timelineleft>li>.timelineleft-item>.timelineleft-header>a {
  font-weight: 500;
  font-size: 18px;
}

.timelineleft-body img {
  width: 150px;
  height: 100px;
}

.timelineleft>li>.timelineleft-item>.timelineleft-body,
.timelineleft>li>.timelineleft-item>.timelineleft-footer {
  padding: .75rem 1.25rem;
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft>li>.fa,
.timelineleft>li>.glyphicon,
.timelineleft>li>.ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  color: #fff;
  background: #1753fc;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}

/*vertical-timline 02*/
/*vertical-timline 03*/
.timeline {
  position: relative;
  padding: 4px 0 0 0;
  margin-top: 22px;
  list-style: none;
}

.timeline>li:nth-child(even) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  right: -100px;
}

.timeline>li:nth-child(odd) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  left: -100px;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
  min-height: 170px;
}

.timeline>li .timeline-panel {
  position: relative;
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  z-index: 10;
  position: absolute;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-left: -80px;
  overflow: hidden;
}

.timeline>li .timeline-image h4 {
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0;
  font-weight: 600;
}

.timeline .timeline-heading h4.subheading {
  margin: 0;
  padding: 0;
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  color: #8492a6;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
  margin-bottom: 0;
  color: #808080;
}

/*Style for even div.line*/
.timeline>li:nth-child(odd) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 690px;
  width: 4px;
  height: 340px;
  -ms-transform: rotate(-44deg);
  /* IE 9 */
  -webkit-transform: rotate(-44deg);
  /* Safari */
  transform: rotate(-44deg);
}

/*Style for odd div.line*/
.timeline>li:nth-child(even) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 450px;
  width: 4px;
  height: 340px;
  -ms-transform: rotate(44deg);
  /* IE 9 */
  -webkit-transform: rotate(44deg);
  /* Safari */
  transform: rotate(44deg);
}

/* Medium Devices, .visible-md-* */

/*vertical-timline 03*/


/*---- Time line -----*/

.wrapper {

  position: relative;
  transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #e85c20;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -25px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 180px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #e85c20 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 16%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

.cbp_tmtimeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative
}

.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 3px;
  background: #e4e6e8;
  left: 20%;
  margin-left: -6px
}

.cbp_tmtimeline>li {
  position: relative
}

.cbp_tmtimeline>li:first-child .cbp_tmtime span.large {
  font-size: 17px !important;
  font-weight: 700
}

.cbp_tmtimeline>li:first-child .cbp_tmicon {}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmtime span:last-child {
  font-size: 13px
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel {
  background: #fff;
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #ffffff;
}

.cbp_tmtimeline>li .empty span {
  color: rgba(0, 0, 0, 0.5);
}

.cbp_tmtimeline>li .cbp_tmtime {
  display: block;
  width: 21%;
  padding-right: 70px;
  position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
  display: block;
  text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
  font-size: 15px;
  font-weight: 700
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
  font-size: 14px;
}

.cbp_tmtimeline>li .cbp_tmlabel {
  margin: 0 0 30px 25%;
  background: #fff;
  padding: 1.2em;
  position: relative;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
}

.cbp_tmtimeline>li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #ffffff;
  border-width: 10px;
  top: 10px
}

.cbp_tmtimeline>li .cbp_tmlabel blockquote {
  font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel .map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
  -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
  box-shadow: 0px 0px 0px 1px #ebebeb;
  background: #3d3780 !important
}

.cbp_tmtimeline>li .cbp_tmlabel h2 {
  margin: 0px;
  padding: 0 0 5px 0;
  line-height: 26px;
  font-size: 18px;
  font-weight: 600;
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a {
  font-size: 20px
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a:hover {
  text-decoration: none
}

.cbp_tmtimeline>li .cbp_tmlabel h2 span {
  font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
  margin-bottom: 0;
}

.cbp_tmtimeline>li .cbp_tmicon {
  width: 24px;
  height: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 15px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px
}

.cbp_tmtimeline .map {
  padding-top: 0 !important;
}

/*vertical-timline*/

/*vertical-timline 02*/
/** timeline left **/
.timelineleft {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}

.timelineleft:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(152, 166, 173, 0.3);
  left: 31px;
  margin: 0;
  border-radius: 2px;
}

.timelineleft>li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}

.timelinelft>li:before,
.timelineleft>li:after {
  content: " ";
  display: table;
}

.timelineleft>.timeleft-label>span {
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  background-color: #1753fc;
  color: #ffffff;
  border-radius: 0px;
}

.timelineleft>li:after {
  clear: both;
}

.timelineleft>li>.timelineleft-item {
  -webkit-box-shadow: 0 1px 1px rgba(107, 122, 144, 0.1);
  box-shadow: 0 2px 17px 2px rgb(208, 201, 243, .5);
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
  border-radius: 7px;
}

.timelineleft>li>.timelineleft-item>.time {
  color: #999;
  float: right;
  padding: 12px;
  font-size: 12px;
}

.timelineleft>li>.timelineleft-item>.timelineleft-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid rgba(107, 122, 144, 0.1);
  padding: .75rem 1.25rem;
  font-size: 20px;
  line-height: 1.1;
}

.timelineleft>li>.timelineleft-item>.timelineleft-header>a {
  font-weight: 500;
  font-size: 18px;
}

.timelineleft-body img {
  width: 150px;
  height: 100px;
}

.timelineleft>li>.timelineleft-item>.timelineleft-body,
.timelineleft>li>.timelineleft-item>.timelineleft-footer {
  padding: .75rem 1.25rem;
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft>li>.fa,
.timelineleft>li>.glyphicon,
.timelineleft>li>.ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  color: #fff;
  background: #1753fc;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}

/*vertical-timline 02*/
/*vertical-timline 03*/
.timeline {
  position: relative;
  padding: 4px 0 0 0;
  margin-top: 22px;
  list-style: none;
}

.timeline>li:nth-child(even) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  right: -100px;
}

.timeline>li:nth-child(odd) {
  position: relative;
  margin-bottom: 50px;
  height: 180px;
  left: -100px;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
  min-height: 170px;
}

.timeline>li .timeline-panel {
  position: relative;
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  z-index: 10;
  position: absolute;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-left: -80px;
  overflow: hidden;
}

.timeline>li .timeline-image h4 {
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0;
  font-weight: 600;
}

.timeline .timeline-heading h4.subheading {
  margin: 0;
  padding: 0;
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  color: #8492a6;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
  margin-bottom: 0;
  color: #808080;
}

/*Style for even div.line*/
.timeline>li:nth-child(odd) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 690px;
  width: 4px;
  height: 340px;
  -ms-transform: rotate(-44deg);
  /* IE 9 */
  -webkit-transform: rotate(-44deg);
  /* Safari */
  transform: rotate(-44deg);
}

/*Style for odd div.line*/
.timeline>li:nth-child(even) .line:before {
  content: "";
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 450px;
  width: 4px;
  height: 340px;
  -ms-transform: rotate(44deg);
  /* IE 9 */
  -webkit-transform: rotate(44deg);
  /* Safari */
  transform: rotate(44deg);
}

/* Medium Devices, .visible-md-* */

/*vertical-timline 03*/


/*---- Time line -----*/


* {
  scrollbar-width: thin;
  // scrollbar-color: #888 #f1f1f1;      
}

.choices__list--dropdown {
  z-index: 9999;
}

.btn-select {
  width: 100%;
  height: 44px;
  border-radius: 7px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.btn-select:hover li {
  margin-left: 0px;
}

.btn-select:hover {
  background-color: #F4F3F3;
  border: 1px solid transparent;
  box-shadow: inset 0 0px 0px 1px #ccc;
}

.btn-select:focus {
  outline: none;
}

.vodiapicker {
  display: none;
}

.salt {
  cursor: pointer;
  width: 3.125rem;
  height: 1.75rem;
  border-radius: 0.25rem;
}


.btn-select li {
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  padding-left: 10px;
  justify-content: space-between;
  padding-right: 10px;
}

#a li {
  list-style: none;
  padding-top: 10px;
  display: flex;
  padding-left: 10px;
  justify-content: space-between;
  padding-right: 10px;
  cursor: pointer;
}

.hex {
  margin-top: 15px;
  width: 52px;
  cursor: pointer;
  height: 30px;
  background-color: #555;
  border-color: #555;
  position: relative;
  display: inline-block;
}

.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 15px solid;
  border-color: inherit;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  position: absolute;
  top: -15px;
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -15px;
  border-top: 15px solid;
  border-color: inherit;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.form-select,
.form-control {
  line-height: 1 !important;
  // max-height: 44px;
}

// .cdk-drop-list-dragging .cdk-drag {
//   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }

// /* Animate an item that has been dropped. */
// .cdk-drag-animating {
//   transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
// }

.cdk-drag-handle
{
  margin-top: 8px;
  margin-left: -4px;
}

.error-msg {
  color: #d11b1b;
  font-size: 14px;
  font-weight: 400;
}

[data-pc-theme=dark] .card {
  --bs-card-border-color: #303f50;
  --bs-card-bg: #1b232d;
}

[data-pc-theme=dark] .badge.bg-light-primary {
  background: #ff7200;
  color: #ffffff;
  border-color: #ff7200;
}

[data-pc-theme=dark] .bg-light-primary {
  background: #ff7200;
  color: #ffffff;
  border-color: #ff7200;
}

[data-pc-theme=dark] .list-group {
  --bs-list-group-border-color: #303f50;
}

[data-pc-theme=dark] .daterangepicker,
[data-pc-theme=dark] .daterangepicker .calendar-table {
  background-color: #1b232d;
  border: 1px solid #303f50;
}


[data-pc-theme=dark] .daterangepicker td.off,
[data-pc-theme=dark] .daterangepicker td.off.in-range,
[data-pc-theme=dark] .daterangepicker td.off.start-date,
[data-pc-theme=dark] .daterangepicker td.off.end-date {
  background-color: transparent;
  border-color: transparent;
  color: #999;
}

[data-pc-theme=dark] .pagination {
  background-color: transparent !important;
  border: 1px solid #303f50 !important;
}

[data-pc-theme=dark] .pagination li a {
  background-color: transparent !important;
  border: 1px solid #303f50 !important;
}

[data-pc-theme=dark] .pagination>.active>a,
[data-pc-theme=dark] .pagination>.active>span,
[data-pc-theme=dark] .pagination>.active>a:hover,
[data-pc-theme=dark] .pagination>.active>span:hover,
[data-pc-theme=dark] .pagination>.active>a:focus,
[data-pc-theme=dark] .pagination>.active>span:focus {
  background: #ff7200 !important;
  ;
  color: #999;
  border-color: #ff7200 !important;
}

[data-pc-theme=dark] .form-select-sm {
  background-color: #263240;
  border-color: #303f50 !important;
  color: #999;
}

.h-100 {
  height: 100% !important;
}

.pc-header .dropdown-user-profile {
  min-width: 284px !important;
  max-width: 100%;
}

.choices__inner {
  min-height: 42px !important;
}


button:disabled, button[disabled],
input:disabled,input[disabled],
select:disabled,select[disabled]
{
  cursor: not-allowed;
  pointer-events: visible !important;
}

::-webkit-search-cancel-button{

}

// .was-validated .ng-valid 
// {
//   border-color: #228b22;
//   padding-right: calc(1.5em + 1.6rem);
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232ca87f' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
//   background-repeat: no-repeat;
//   background-position: right calc(0.375em + 0.4rem) center;
//   background-size: calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
// }

.was-validated .ng-invalid
{
  border-color: #d11b1b !important;
  padding-right: calc(1.5em + 1.6rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.4rem) center;
  background-size: calc(0.75em + 0.8rem) calc(0.75em + 0.8rem);
}

.dropzone .dz-preview .dz-progress
{
  background: transparent !important;
}

.w-150
{
  width: 150px !important;
}

.w-200
{
  width: 200px !important;
}

.w-220
{
  width: 220px !important;
}

.w-300
{
  width: 300px !important;
}


.w-350
{
  width: 350px !important;
}

.w-400
{
  width: 400px !important;
}